import merge from 'lodash/merge';

import login from './login';
import core from './core';

export default {
  language: 'br',
  country: 'br',
  name: 'Português (Brasil)',
  embeddedLocale: merge(login, core),
};
