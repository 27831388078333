export default {
  format: {
    date: 'd/M/yyyy',
    time: 'p',
    dateTime: '$t(format:date) $t(format:time)',
    longDate: 'd MMM',
    longDateTime: "d 'de' MMMM 'às' p",
    fullDate: 'd MMM, y',
    fullDateTime: "d 'de' MMMM, y 'às' p",
  },

  translation: {
    common: {
      aboutPlanka: 'Sobre Knaban', // Não vai ser usado
      account: 'Conta',
      actions: 'Ações',
      addAttachment_title: 'Adicionar Anexo',
      addComment: 'Adicionar Comentário',
      addManager_title: 'Adicionar Gerente',
      addMember_title: 'Adicionar Membro',
      addUser_title: 'Adicionar Usuário',
      administrator: 'Administrador',
      all: 'Todos',
      allChangesWillBeAutomaticallySavedAfterConnectionRestored:
        'Todas as alterações serão salvas automaticamente<br />após a conexão ser restaurada.',
      areYouSureYouWantToDeleteThisAttachment: 'Tem certeza de que deseja excluir este anexo?',
      areYouSureYouWantToDeleteThisBoard: 'Tem certeza de que deseja excluir este quadro?', // Não vai ser usado
      areYouSureYouWantToDeleteThisCard: 'Tem certeza de que deseja excluir este cartão?',
      areYouSureYouWantToDeleteThisComment: 'Tem certeza de que deseja excluir este comentário?',
      areYouSureYouWantToDeleteThisLabel: 'Tem certeza de que deseja excluir esta etiqueta?',
      areYouSureYouWantToDeleteThisList: 'Tem certeza de que deseja excluir esta lista?',
      areYouSureYouWantToDeleteThisProject: 'Tem certeza de que deseja excluir este projeto?', // Não vai ser usado
      areYouSureYouWantToDeleteThisTask: 'Tem certeza de que deseja excluir esta tarefa?',
      areYouSureYouWantToDeleteThisUser: 'Tem certeza de que deseja excluir este usuário?',
      areYouSureYouWantToLeaveBoard: 'Tem certeza de que deseja sair do quadro?', // Não vai ser usado
      areYouSureYouWantToLeaveProject: 'Tem certeza de que deseja sair do projeto?', // Não vai ser usado
      areYouSureYouWantToRemoveThisManagerFromProject:
        'Tem certeza de que deseja remover este gerente do projeto?', // Não vai ser usado
      areYouSureYouWantToRemoveThisMemberFromBoard:
        'Tem certeza de que deseja remover este membro do quadro?', // Não vai ser usado
      attachment: 'Anexo',
      attachments: 'Anexos',
      authentication: 'Autenticação',
      background: 'Fundo', // Não vai ser usado
      board: 'Quadro', // Não vai ser usado
      boardNotFound_title: 'Quadro não encontrado', // Não vai ser usado
      canComment: 'Pode comentar',
      canEditContentOfBoard: 'Pode editar o conteúdo do quadro.', // Não vai ser usado
      canOnlyViewBoard: 'Só pode visualizar o quadro.', // Não vai ser usado
      cardActions_title: 'Ações do Cartão',
      cardNotFound_title: 'Cartão não encontrado',
      cardOrActionAreDeleted: 'Cartão ou ação excluídos.',
      color: 'Cor',
      createBoard_title: 'Criar Quadro', // Não vai ser usado
      createLabel_title: 'Criar Etiqueta',
      createNewOneOrSelectExistingOne: 'Criar uma nova ou selecionar<br />uma existente.',
      createProject_title: 'Criar Projeto', // Não vai ser usado
      createTextFile_title: 'Criar Arquivo de Texto',
      currentPassword: 'Senha atual',
      dangerZone_title: 'Zona de Perigo',
      date: 'Data',
      dueDate_title: 'Data',
      deleteAttachment_title: 'Excluir Anexo',
      deleteBoard_title: 'Excluir Quadro', // Não vai ser usado
      deleteCard_title: 'Excluir Cartão',
      deleteComment_title: 'Excluir Comentário',
      deleteLabel_title: 'Excluir Etiqueta',
      deleteList_title: 'Excluir Lista',
      deleteProject_title: 'Excluir Projeto', // Não vai ser usado
      deleteTask_title: 'Excluir Tarefa',
      deleteUser_title: 'Excluir Usuário',
      description: 'Descrição',
      detectAutomatically: 'Detectar automaticamente',
      dropFileToUpload: 'Solte o arquivo para enviar',
      editor: 'Editor',
      editAttachment_title: 'Editar Anexo',
      editAvatar_title: 'Editar Avatar',
      editBoard_title: 'Editar Quadro', // Não vai ser usado
      editDueDate_title: 'Editar Data',
      editEmail_title: 'Editar E-mail',
      editInformation_title: 'Editar Informações',
      editLabel_title: 'Editar Etiqueta',
      editPassword_title: 'Editar Senha',
      editPermissions_title: 'Editar Permissões',
      editStopwatch_title: 'Editar Cronômetro', // Não vai ser usado
      editUsername_title: 'Editar Nome de Usuário',
      email: 'E-mail',
      emailAlreadyInUse: 'E-mail já em uso',
      enterCardTitle:
        'Digite o título do cartão... Ctrl+Enter (Cmd+Enter no Mac) para abrir automaticamente.',
      enterDescription: 'Digite a descrição...',
      enterFilename: 'Digite o nome do arquivo',
      enterListTitle: 'Digite o título da lista...',
      enterProjectTitle: 'Digite o título do projeto', // Não vai ser usado
      enterTaskDescription: 'Digite a descrição da tarefa...',
      filterByLabels_title: 'Filtrar por Etiquetas',
      filterByMembers_title: 'Filtrar por Membros',
      fromComputer_title: 'Do Computador',
      fromTrello: 'Do Trello',
      general: 'Geral',
      hours: 'Horas',
      importBoard_title: 'Importar Quadro',
      invalidCurrentPassword: 'Senha atual inválida',
      labels: 'Etiquetas',
      language: 'Idioma',
      leaveBoard_title: 'Sair do Quadro', // Não vai ser usado
      leaveProject_title: 'Sair do Projeto', // Não vai ser usado
      list: 'Lista',
      listActions_title: 'Ações da Lista',
      managers: 'Gerentes',
      members: 'Membros',
      minutes: 'Minutos',
      moveCard_title: 'Mover Cartão',
      name: 'Nome',
      newEmail: 'Novo e-mail',
      newPassword: 'Nova senha',
      newUsername: 'Novo nome de usuário',
      noConnectionToServer: 'Sem conexão com o servidor',
      noBoards: 'Sem quadros',
      noLists: 'Sem listas',
      noProjects: 'Sem projetos',
      notifications: 'Notificações',
      noUnreadNotifications: 'Nenhuma notificação não lida.',
      openBoard_title: 'Abrir Quadro', // Não vai ser usado
      optional_inline: 'opcional',
      organization: 'Organização',
      phone: 'Telefone',
      preferences: 'Preferências',
      pressPasteShortcutToAddAttachmentFromClipboard:
        'Dica: pressione Ctrl+V (Cmd+V no Mac) para adicionar um anexo da área de transferência.',
      project: 'Projeto', // Não vai ser usado
      projectNotFound_title: 'Projeto não encontrado', // Não vai ser usado
      removeManager_title: 'Remover Gerente',
      removeMember_title: 'Remover Membro',
      searchLabels: 'Procurar etiquetas...',
      searchMembers: 'Procurar membros...',
      searchUsers: 'Procurar usuários...',
      seconds: 'Segundos',
      selectBoard: 'Selecionar quadro', // Não vai ser usado
      selectList: 'Selecionar lista',
      selectPermissions_title: 'Selecionar Permissões',
      selectProject: 'Selecionar projeto', // Não vai ser usado
      settings: 'Configurações',
      stopwatch: 'Cronômetro', // Não vai ser usado
      subscribeToMyOwnCardsByDefault: 'Inscrever-se automaticamente nos meus próprios cartões',
      taskActions_title: 'Ações da Tarefa',
      tasks: 'Tarefas',
      thereIsNoPreviewAvailableForThisAttachment: 'Não há visualização disponível para este anexo.',
      time: 'Tempo',
      title: 'Título',
      userActions_title: 'Ações do Usuário',
      userAddedThisCardToList: '<0>{{user}}</0><1> adicionou este cartão a {{list}}</1>',
      userLeftNewCommentToCard:
        '{{user}} deixou um novo comentário «{{comment}}» para <2>{{card}}</2>',
      userMovedCardFromListToList: '{{user}} moveu <2>{{card}}</2> de {{fromList}} para {{toList}}',
      userMovedThisCardFromListToList:
        '<0>{{user}}</0><1> moveu este cartão de {{fromList}} para {{toList}}</1>',
      username: 'Nome de Usuário',
      usernameAlreadyInUse: 'Nome de usuário já em uso',
      users: 'Usuários',
      version: 'Versão',
      viewer: 'Visualizador',
      writeComment: 'Escreva um comentário...',
    },

    action: {
      addAnotherCard: 'Adicionar outro cartão',
      addAnotherList: 'Adicionar outra lista',
      addAnotherTask: 'Adicionar outra tarefa',
      addCard: 'Adicionar cartão',
      addCard_title: 'Adicionar Cartão',
      addComment: 'Adicionar comentário',
      addList: 'Adicionar lista',
      addMember: 'Adicionar membro',
      addMoreDetailedDescription: 'Adicionar descrição mais detalhada',
      addTask: 'Adicionar tarefa',
      addToCard: 'Adicionar ao cartão',
      addUser: 'Adicionar usuário',
      createBoard: 'Criar quadro', // Não vai ser usado
      createFile: 'Criar arquivo',
      createLabel: 'Criar etiqueta',
      createNewLabel: 'Criar nova etiqueta',
      createProject: 'Criar projeto', // Não vai ser usado
      delete: 'Excluir',
      deleteAttachment: 'Excluir anexo',
      deleteAvatar: 'Excluir avatar',
      deleteBoard: 'Excluir quadro', // Não vai ser usado
      deleteCard: 'Excluir cartão',
      deleteCard_title: 'Excluir Cartão',
      deleteComment: 'Excluir comentário',
      deleteImage: 'Excluir imagem',
      deleteLabel: 'Excluir etiqueta',
      deleteList: 'Excluir lista',
      deleteList_title: 'Excluir Lista',
      deleteProject: 'Excluir projeto', // Não vai ser usado
      deleteProject_title: 'Excluir Projeto', // Não vai ser usado
      deleteTask: 'Excluir tarefa',
      deleteTask_title: 'Excluir Tarefa',
      deleteUser: 'Excluir usuário',
      edit: 'Editar',
      editDueDate_title: 'Editar Data',
      editDescription_title: 'Editar Descrição',
      editEmail_title: 'Editar E-mail',
      editInformation_title: 'Editar Informações',
      editPassword_title: 'Editar Senha',
      editPermissions: 'Editar permissões',
      editStopwatch_title: 'Editar Cronômetro', // Não vai ser usado
      editTitle_title: 'Editar Título',
      editUsername_title: 'Editar Nome de Usuário',
      hideDetails: 'Ocultar detalhes',
      import: 'Importar',
      leaveBoard: 'Sair do quadro', // Não vai ser usado
      leaveProject: 'Sair do projeto', // Não vai ser usado
      logOut_title: 'Sair',
      makeCover_title: 'Tornar Capa', // Não vai ser usado
      move: 'Mover',
      moveCard_title: 'Mover Cartão',
      remove: 'Remover',
      removeBackground: 'Remover fundo', // Não vai ser usado
      removeCover_title: 'Remover Capa', // Não vai ser usado
      removeFromBoard: 'Remover do quadro', // Não vai ser usado
      removeFromProject: 'Remover do projeto', // Não vai ser usado
      removeManager: 'Remover gerente',
      removeMember: 'Remover membro',
      save: 'Salvar',
      showAllAttachments: 'Mostrar todos os anexos ({{hidden}} ocultos)',
      showDetails: 'Mostrar detalhes',
      showFewerAttachments: 'Mostrar menos anexos',
      start: 'Iniciar',
      stop: 'Parar',
      subscribe: 'Inscrever-se', // Não vai ser usado
      unsubscribe: 'Cancelar inscrição', // Não vai ser usado
      uploadNewAvatar: 'Enviar novo avatar',
      uploadNewImage: 'Enviar nova imagem',
    },
  },
};
